function openSM(e, ddItem) {
    if (
        e.target.classList.contains('float-xs-right') ||
        e.target.classList.contains('navbar-toggler') ||
        e.target.classList.contains('material-icons')) {
        e.preventDefault();
        var submenu = ddItem.nextElementSibling
        if (!submenu.classList.contains('in')) {
            submenu.style.height = submenu.scrollHeight + 'px';
            submenu.classList.toggle('in');
        } else {
            submenu.style.height = '0';
            submenu.classList.toggle('in');
        }
    }
}

function openNavMenuMobile() {
    document.querySelector('body').classList.toggle('menu-open');
}
function nav() {
    let toggleMenu = document.querySelectorAll('[data-toggle="menu"]').length;
    let nav = document.querySelector('.header-nav');
    if (toggleMenu > 0) {
        var toggleMenu1 = document.querySelector('[data-toggle="menu"]');
        let toggleCLN = toggleMenu1.cloneNode(true);
        nav.prepend(toggleCLN);

        var toggleMenus = document.querySelectorAll('[data-toggle="menu"]');

        [].forEach.call(toggleMenus, (toggleMenu) => {
            toggleMenu.addEventListener('click', openNavMenuMobile);
            toggleMenu.addEventListener('touch', openNavMenuMobile);
        });
    }

    // dropdown
    var ddItems = document.querySelectorAll('.dropdown-item');
    if (ddItems.length > 0) {
        [].forEach.call(ddItems, (ddItem) => {
            ddItem.addEventListener('click', (e) => { openSM(e, ddItem) })
            ddItem.addEventListener('touch', (e) => { openSM(e, ddItem) })
        });
    }
}
window.addEventListener('DOMContentLoaded', nav);