function scrollToTop() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
}
function btt() {
    let button = document.getElementById('back-to-top');
    button.addEventListener('click', scrollToTop)
}

window.addEventListener('DOMContentLoaded', btt);